import React, { useEffect } from "react";
import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import RegisterForm from "./componets/Register/Register.jsx";
import Information from "../../components/Information/Information.jsx";

const Register = ({ children }) => {
  useEffect(() => {
    document.title = "Registrar";
  }, []);

  return (
    <div>
      <Header />
      <RegisterForm />
      <Information />
      <Footer />
    </div>
  );
};

export default Register;
