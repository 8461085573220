import React, { useRef, useEffect, useState } from "react";
import Card from "../../../../components/Card/Card";
import "./style.css";
import { useParams } from "react-router-dom";

const CardListCategory = ({}) => {
  const containerRef = useRef(null);
  const [products, setProducts] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/products`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              sub_category_id: id,
            }),
          }
        );
        const data = await response.json();
        setProducts(data);
      } catch (error) {}
    };

    fetchProducts();
  }, [id]);

  const handleScroll = (direction) => {
    if (containerRef.current) {
      const scrollAmount = 300;
      const newScrollPosition =
        containerRef.current.scrollLeft +
        (direction === "left" ? -scrollAmount : scrollAmount);

      containerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div className="carousel-container-category">
        <div className="carousel-wrapper-category" ref={containerRef}>
          <div className="carousel-track-category">
            {Array.isArray(products) ? (
              products.map((product) => <Card key={product.id} {...product} />)
            ) : (
              <p>Nenhum produto encontrado.</p>
            )}
          </div>
        </div>
        <div className="dotted-line"></div>
        <button className="load-more-button">
          Carregar mais produtos <span>+</span>
        </button>
      </div>
    </>
  );
};

export default CardListCategory;
