import React, { useRef, useEffect, useState } from "react";
import Card from "../Card/Card";
import "./style.css";

const CardList = () => {
  const containerRef = useRef(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/products`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              sub_category_id: 1,
            }),
          }
        );
        const data = await response.json();
        setProducts(data);
      } catch (error) {}
    };

    fetchProducts();
  }, []);

  const handleScroll = (direction) => {
    if (containerRef.current) {
      const scrollAmount = 350;
      const newScrollPosition =
        containerRef.current.scrollLeft +
        (direction === "left" ? -scrollAmount : scrollAmount);

      containerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="carousel-container">
      <button
        className="carousel-button carousel-button-left"
        onClick={() => handleScroll("left")}
        aria-label="Anterior"
      >
        <span>‹</span>
      </button>

      <div className="carousel-wrapper" ref={containerRef}>
        <div className="carousel-track">
          {Array.isArray(products) ? (
            products.map((product) => <Card key={product.id} {...product} />)
          ) : (
            <p>Nenhum produto encontrado.</p>
          )}
        </div>
      </div>

      <button
        className="carousel-button carousel-button-right"
        onClick={() => handleScroll("right")}
        aria-label="Próximo"
      >
        <span>›</span>
      </button>
    </div>
  );
};

export default CardList;
