import React, { useEffect } from "react";

import Header from "../../components/Header/Header";
import Information from "../../components/Information/Information";
import Footer from "../../components/Footer/Footer";
import ThanksForm from "./components/ThanksForm/ThanksForm";
function ThanksPage() {
  useEffect(() => {
    document.title = "Obrigado";
  }, []);

  // Mover o header, a informação e o footer
  return (
    <>
      <Header /> {/* Header movido para o início */}
      <ThanksForm />
      <Information />
      <Footer /> {/* Footer movido para o final */}
    </>
  );
  // ... código existente ...
}

// Adicione a exportação padrão
export default ThanksPage; // Certifique-se de que o componente está sendo exportado
