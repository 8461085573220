// src/Pages/components/Description/Description.jsx
import React from "react";
import "./style.css";

const Description = ({ description }) => {
  return (
    <div className="description">
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

export default Description;
