import React, { useRef, useEffect, useState } from "react";
import BannerImg from "../BannerImg/BannerImg";
import "./style.css";

const BannerImgList = ({ parametro }) => {
  const containerRef = useRef(null);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/banner-imgs`
        );
        const data = await response.json();
        setBanners(data);
      } catch (error) {}
    };

    fetchBanners();
  }, []);

  const handleScroll = (direction) => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const newScrollPosition =
        containerRef.current.scrollLeft +
        (direction === "left" ? -containerWidth : containerWidth);

      containerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="carousel-container-bannerImgList">
      <p className="title-bannerImgList">
        Os melhores momentos são aqui, na Cacau Show!
      </p>
      <button
        className="carousel-button carousel-button-left"
        onClick={() => handleScroll("left")}
        aria-label="Anterior"
      >
        <span>‹</span>
      </button>

      <div className="carousel-wrapper" ref={containerRef}>
        <div className="carousel-track all">
          {Array.isArray(banners) ? (
            banners.map((banner) => (
              <BannerImg
                key={banner.id}
                image={banner.image}
                title={banner.title}
              />
            ))
          ) : (
            <p>Nenhum produto disponível.</p>
          )}
        </div>
      </div>

      <button
        className="carousel-button carousel-button-right"
        onClick={() => handleScroll("right")}
        aria-label="Próximo"
      >
        <span>›</span>
      </button>
    </div>
  );
};

export default BannerImgList;
