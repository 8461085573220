import React, { useEffect } from "react";
import HeaderLogin from "../Login/components/HeaderLogin/HeaderLogin.jsx";
import PaymentForm from "./components/PaymentForm/PaymentForm.jsx";
import Footer from "../../components/Footer/Footer.jsx";

const Payments = ({ children }) => {
  useEffect(() => {
    document.title = "Pagamentos";
  }, []);

  return (
    <div>
      <HeaderLogin />
      <PaymentForm />
      <Footer />
    </div>
  );
};

export default Payments;
