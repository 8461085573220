// src/Pages/Cart/components/CardCart/CardCart.jsx
import React, { useState, useEffect } from "react";
import "./style.css";

const CardCart = ({ item, onRemove }) => {
  const [quantity, setQuantity] = useState(item.quantity);
  const [inputValue, setInputValue] = useState("");

  // Atualiza o local storage sempre que a quantidade mudar
  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    const existingProductIndex = cartItems.findIndex(
      (cartItem) => cartItem.id === item.id
    );

    if (existingProductIndex > -1) {
      // Atualiza a quantidade do item existente
      cartItems[existingProductIndex].quantity = quantity;
    } else {
      // Se o item não existir, adiciona ao carrinho
      cartItems.push({ ...item, quantity });
    }

    localStorage.setItem("cart", JSON.stringify(cartItems)); // Salva o carrinho atualizado

    // Dispara um evento personalizado para atualizar o cabeçalho do carrinho
    const event = new Event("cartUpdated");
    window.dispatchEvent(event);
  }, [quantity, item]);

  const handleIncrease = () => {
    setQuantity((prev) => prev + 1); // Aumenta a quantidade
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1); // Diminui a quantidade
    }
  };

  const handleRemove = () => {
    onRemove(item.id);
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    const updatedCartItems = cartItems.filter(
      (cartItem) => cartItem.id !== item.id
    ); // Remove o item do carrinho
    localStorage.setItem("cart", JSON.stringify(updatedCartItems)); // Atualiza o local storage
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div className="cardcart-cart">
      <div className="image-title-container-cart">
        <img
          src={item.images[0]}
          alt="Produto"
          className="product-image-cart"
        />
        <div className="product-info-cart">
          <h3 className="product-name-cart">{item.name}</h3>
          <button className="remove-button-cart" onClick={handleRemove}>
            REMOVER
            <i className="fas fa-trash"></i>
          </button>
        </div>
      </div>
      <div className="price-info-cart">
        <div className="price-unit-cart">
          <span className="label-price-unit">PREÇO UNITÁRIO (R$)</span>
          <span className="unit-price-value">
            R$ {Math.floor(Number(item.second_price) * 100) / 100}
          </span>
          <span className="current-price-value">
            R$ {Math.floor(Number(item.price) * 100) / 100}
          </span>
        </div>
        <div className="quantity-control">
          <button className="decrement" onClick={handleDecrease}>
            -
          </button>
          <input
            type="text"
            className="quantity-input"
            value={quantity}
            onChange={handleChange}
          />
          <button className="increment" onClick={handleIncrease}>
            +
          </button>
        </div>
        <div className="subtotal-cart">
          <span className="label-subtotal">SUBTOTAL (R$):</span>
          <span className="subtotal-value-cart">
            R$ {Math.floor(Number(item.price) * quantity * 100) / 100}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CardCart;
