import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CardListCategory from "./components/CardListCategory/CardListCategory";
import Information from "../../components/Information/Information";
import Breadcrumb from "./components/BreadcrumbSearchCategory/BreadcrumbCategory";
const SearchCategory = () => {
  useEffect(() => {
    document.title = "Buscar Categoria";
  }, []);

  return (
    <>
      <Header />
      <Breadcrumb />
      <CardListCategory />
      <Information />
      <Footer />
    </>
  );
};

export default SearchCategory;
