// src/components/Banner/Banner.jsx
import React from "react";
import { useEffect, useState } from "react"; // Importa useEffect e useState
import { useNavigate } from "react-router-dom"; // Importa useHistory do react-router-dom

const Banner = ({ image }) => {
  const [loadedImage, setLoadedImage] = useState(null);
  const navigate = useNavigate(); // Inicializa o hook useHistory

  useEffect(() => {
    if (image) {
      setLoadedImage(image);
    }
  }, [image]);

  const handleClick = () => {
    // Redireciona o cliente para a categoria 20
    navigate("/category/20");
  };

  return (
    <div className="banner-container" onClick={handleClick}>
      {loadedImage ? (
        <img
          src={loadedImage}
          alt="Banner Chocofriday"
          className="banner-image"
        />
      ) : (
        <p>Carregando...</p>
      )}
    </div>
  );
};

export default Banner;
