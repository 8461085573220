// src/Pages/Cart/components/ResumeOrder/ResumeOrder.jsx

import React, { useEffect, useState } from "react";
import "./style.css";

const ResumeOrderComponent = () => {
  const [cart, setCart] = useState([]);
  const [shippingCost, setShippingCost] = useState(0);

  const updateCart = () => {
    const storedOrder = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(storedOrder);
  };

  useEffect(() => {
    updateCart();

    const updateShippingCost = () => {
      const storedShippingCost =
        localStorage.getItem("deliveryOption") === "Total Express"
          ? 0.0
          : localStorage.getItem("deliveryOption") === "Correios PAC"
          ? 10.0
          : localStorage.getItem("deliveryOption") === "Correios Sedex"
          ? 20.0
          : 0;
      setShippingCost(storedShippingCost);
    };

    updateShippingCost();

    window.addEventListener("cartUpdated", updateCart);
    window.addEventListener("deliveryOptionUpdated", updateShippingCost);

    return () => {
      window.removeEventListener("cartUpdated", updateCart);
      window.removeEventListener("deliveryOptionUpdated", updateShippingCost);
    };
  }, []);

  const totalPrice = cart.reduce(
    (acc, item) => acc + parseFloat(item.price) * item.quantity,
    0
  );

  const totalWithShipping = parseFloat(totalPrice) + shippingCost;

  const handleCheckout = () => {
    const cartData = localStorage.getItem("cart");

    if (cartData) {
      const orderId = Date.now();
      saveOrderId(orderId);

      window.location.href = `/checkout/${orderId}`;
    } else {
      console.error("Carrinho vazio. Não é possível finalizar a compra.");
    }
  };

  const saveOrderId = (id) => {
    localStorage.setItem("orderId", id);
  };

  return (
    <div className="ResumeOrder_card">
      <h2 className="ResumeOrder_h2">Resumo do pedido</h2>
      <div className="ResumeOrder_item">
        <span>{cart.length} PRODUTO(S)</span>
        <span>
          R$ {totalPrice.toString().split(".")[0]}.
          {(totalPrice.toString().split(".")[1] || "00").slice(0, 2)}
        </span>
      </div>
      <div className="ResumeOrder_item">
        <span>FRETE</span>
        <span>
          R$ {shippingCost.toString().split(".")[0]}.
          {(shippingCost.toString().split(".")[1] || "00").slice(0, 2)}
        </span>
      </div>
      <hr />
      <div className="ResumeOrder_total">
        <span>TOTAL</span>
        <span>
          R$ {totalWithShipping.toString().split(".")[0]}.
          {(totalWithShipping.toString().split(".")[1] || "00").slice(0, 2)}
        </span>
      </div>
      <button className="ResumeOrder_finalizar" onClick={handleCheckout}>
        FINALIZAR COMPRA
      </button>
    </div>
  );
};

export default ResumeOrderComponent;
