import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Banner from "../Banner/Banner";
import CardList from "../CardList/CardList";
import BannerImgList from "../BannerImgList/BannerImgList";
import CardListDestak from "../CardListDestak/CardListDestak";
import BannerImgListLines from "../BannerImgListLines/BannerImgListLines";
import BannerClub from "../BannerClub/BannerClub";
import Information from "../Information/Information";
import BannerList from "../BannerList/BannerList";

const Layout = ({ children }) => {
  const productsDestak = [];

  const bannersImgList = [];

  const banners = [];

  const bannersImgListLines = [];

  useEffect(() => {
    document.title = "CacauSow";

    // Salvar UTM no localStorage
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get("utm_source");
    const utmMedium = urlParams.get("utm_medium");
    const utmCampaign = urlParams.get("utm_campaign");
    const utmTerm = urlParams.get("utm_term");
    const utmContent = urlParams.get("utm_content");
    const src = urlParams.get("src");
    const sku = urlParams.get("sku");

    if (utmSource) localStorage.setItem("utm_source", utmSource);
    if (utmMedium) localStorage.setItem("utm_medium", utmMedium);
    if (utmCampaign) localStorage.setItem("utm_campaign", utmCampaign);
    if (utmTerm) localStorage.setItem("utm_term", utmTerm);
    if (utmContent) localStorage.setItem("utm_content", utmContent);
    if (src) localStorage.setItem("src", src);
    if (sku) localStorage.setItem("sku", sku);
  }, []);

  return (
    <div>
      <Header />
      <main>{children}</main>
      <BannerList banners={banners} />
      <BannerImgList banners={bannersImgList} />

      <CardList />
      <CardListDestak products={productsDestak} />
      <BannerImgListLines banners={bannersImgListLines} />
      <BannerClub />
      <Information />
      <Footer />
    </div>
  );
};

export default Layout;
