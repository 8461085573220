import React, { useState } from "react";
import "./styles.css"; // Certifique-se de criar este arquivo CSS

const ImgProduct = ({ images, alt }) => {
  // Verifique se images é um array e não está vazio
  if (!Array.isArray(images) || images.length === 0) {
    return <div>{alt}</div>; // Retorne um fallback ou uma mensagem se não houver imagens
  }

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="carousel">
      <button className="arrow left" onClick={prevImage}>
        ❮
      </button>
      <div className="img-container">
        <img src={images[currentIndex]} alt={`Produto ${currentIndex + 1}`} />
      </div>
      <button className="arrow right" onClick={nextImage}>
        ❯
      </button>
      <div className="indicators-container">
        <div className="indicators">
          {images.map((_, index) => (
            <span
              key={index}
              className={`indicator ${currentIndex === index ? "active" : ""}`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImgProduct;
