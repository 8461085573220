import React from "react";
import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import PaymentPix from "./components/PaymentPix/PaymentPix.jsx";
import Information from "../../components/Information/Information.jsx";

const PaymentPixComponent = ({ children }) => {
  return (
    <div>
      <Header />
      <PaymentPix />
      <Information />
      <Footer />
    </div>
  );
};

export default PaymentPixComponent;
