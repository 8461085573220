// src/Components/LoginForm.jsx
import React, { useState } from "react";
import "./style.css"; // Importando o arquivo CSS
import { useNavigate } from "react-router-dom"; // Importando useNavigate

const LoginForm = () => {
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate(); // Inicializando useNavigate

  const handleInputChange = (e) => {
    const rawValue = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos

    // Permitir apenas até 11 dígitos
    if (rawValue.length <= 11) {
      const value = rawValue
        .replace(/(\d{3})(\d)/, "$1.$2") // Adiciona o primeiro ponto
        .replace(/(\d{3})(\d)/, "$1.$2") // Adiciona o segundo ponto
        .replace(/(\d{3})(\d{2})$/, "$1-$2"); // Adiciona o traço

      setInputValue(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/register"); // Redireciona para a página de registro
  };

  return (
    <div className="login-container">
      <h1>Entrar ou Criar Conta</h1>
      <form onSubmit={handleSubmit}>
        <label>
          * E-mail ou CPF
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder=""
            required
          />
        </label>
        <button className="login-button-continue" type="submit">
          CONTINUAR
        </button>

        <button
          className="login-button-continue"
          type="button"
          onClick={() => navigate("/register")}
        >
          CRIAR CONTA
        </button>
      </form>
      <p>Ao continuar, você concorda com os Termos de uso da Cacau show</p>
      <p>Por favor verifique a Política de Privacidade</p>
    </div>
  );
};

export default LoginForm;
