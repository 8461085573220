// src/Components/DeliveryAddress/DeliveryAddress.jsx
import React from "react";
import "./style.css";
import { FaArrowRight } from "react-icons/fa"; // Importando ícone de seta
import AddressModal from "../AddressModal/AddressModal"; // Importe o AddressModal
import { useNavigate } from "react-router-dom"; // Importando useNavigate

const DeliveryAddress = () => {
  const navigate = useNavigate(); // Inicializando o hook useNavigate
  const [isModalOpen, setModalOpen] = React.useState(false); // Estado para controlar a abertura do modal

  const handleEditClick = () => {
    const deliveryAddress = localStorage.getItem("address");
    if (deliveryAddress) {
      console.log("Redirecionando para pagamentos..."); // Adicionando log para depuração
      navigate("/payments"); // Redireciona para a página de pagamento se o endereço existir
    } else {
      console.log("Abrindo modal..."); // Adicionando log para depuração
      setModalOpen(true); // Abre o modal se não houver endereço
    }
  };

  // Obtendo o endereço do localStorage
  const storedAddress = localStorage.getItem("address");
  let addressDetails;

  if (storedAddress) {
    try {
      addressDetails = JSON.parse(storedAddress);
    } catch (error) {
      console.error("Erro ao analisar o endereço do localStorage:", error);
      addressDetails = {
        street: "Endereço não disponível",
        city: "Cidade não disponível",
        state: "Estado não disponível",
        zip_code: "CEP não disponível",
      };
    }
  } else {
    addressDetails = {
      street: "Via de Acesso Norte Km 32.5, 2",
      city: "Cajamar - SP, 07753580",
      state: "",
      zip_code: "",
    };
  }

  return (
    <div className="delivery-container">
      <h2 className="delivery-title">Endereço de Entrega</h2>
      <p className="delivery-subtitle">
        <FaArrowRight className="arrow-icon" /> Selecione onde quer receber suas
        compras
      </p>
      <div className="options-container">
        <div className="option option-receber">
          {/* <input type="radio" id="receber-pedido" name="delivery-option" /> */}
          <label htmlFor="receber-pedido">Receber Pedido</label>
        </div>
        <div className="option option-retirar">
          {/* <input type="radio" id="retirar-loja" name="delivery-option" /> */}
          <label htmlFor="retirar-loja">Retirar na Loja (Indisponível)</label>
        </div>
      </div>
      <div className="address-card">
        <div className="address-header"></div>
        Utilizar este endereço para receber suas compras
        <div className="address-details">
          <p className="address">{` ${addressDetails.street}, ${addressDetails.city} - ${addressDetails.state}, ${addressDetails.zip_code}`}</p>
        </div>
        <button className="payment-button" onClick={handleEditClick}>
          CONTINUAR COM ESTE ENDEREÇO
        </button>
        <div className="separator">
          <span>ou</span>
        </div>
        <button className="edit-button" onClick={handleEditClick}>
          EDITAR OU ESCOLHER OUTRO ENDEREÇO
        </button>
      </div>
      {isModalOpen && <AddressModal onClose={() => setModalOpen(false)} />}
    </div>
  );
};

export default DeliveryAddress;
