import React from "react";
import "./styles.css";
import { useEffect, useState } from "react";

const BannerImg = ({ image, title }) => {
  return (
    <div
      className="slick-slide slick-current slick-active"
      data-slick-index="0"
      aria-hidden="false"
      style={{ width: "338px" }}
    >
      <div>
        <a
          href="#"
          className="item"
          tabIndex="0"
          style={{ display: "block", width: "100%" }}
        >
          <div className="image-viewBanner">
            <picture>
              <source srcSet={image} media="(min-width: 1024px)" />
              <source srcSet={image} media="(min-width: 768px)" />
              <img
                src={image}
                alt="Momento Chocolatinho"
                style={{ width: "100%" }}
              />
            </picture>
          </div>
          <p className="label">{title}</p>
        </a>
      </div>
    </div>
  );
};

export default BannerImg;
