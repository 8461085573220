// src/Pages/Payments/components/PaymentForm/PaymentForm.jsx

import React, { useState, useEffect, useRef } from "react";
import MaskedInput from "react-text-mask"; // Importando a nova biblioteca de máscara
import "./style.css";
import { useNavigate, Link } from "react-router-dom";
import ModalRefused from "../ModalRefused/ModalRefused"; // Ajuste o caminho conforme necessário

const PaymentForm = () => {
  const [cardNumber, setCardNumber] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardExpirationDate, setCardExpirationDate] = useState("");
  const [cardCvv, setCardCvv] = useState("");
  const [parcels, setParcels] = useState(1);
  const [total, setTotal] = useState(177.24); // Total a pagar
  const [activePaymentMethod, setActivePaymentMethod] = useState("");
  const [showPixStep, setShowPixStep] = useState(false);
  const [showCardStep, setShowCardStep] = useState(false); // Novo estado para o cartão
  const [cartSummary, setCartSummary] = useState({ items: 0, total: 0 }); // Novo estado para o resumo do carrinho
  const [shippingCost, setShippingCost] = useState(0); // Estado para armazenar o custo do frete
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false); // Novo estado para controle de loading
  const navigate = useNavigate();
  const [discountCoupon, setDiscountCoupon] = useState(""); // Novo estado para o cupom de desconto
  const [discountAmount, setDiscountAmount] = useState(0); // Inicializa o estado do desconto

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("cart")) || []; // Puxa o carrinho do localStorage
    const total = cart.reduce(
      (acc, product) => acc + product.price * product.quantity,
      0
    ); // Calcula o total
    setCartSummary({ length: cart.length, total }); // Atualiza o estado do resumo do carrinho

    // Puxa o custo do frete do local storage
    const storedShippingCost =
      localStorage.getItem("deliveryOption") === "Total Express"
        ? 0.0
        : localStorage.getItem("deliveryOption") === "Correios PAC"
        ? 10.0
        : localStorage.getItem("deliveryOption") === "Correios Sedex"
        ? 20.0
        : 0;
    setShippingCost(storedShippingCost); // Atualiza o estado do frete

    const storedCoupon = localStorage.getItem("discount_coupon"); // Puxa o cupom do localStorage
    const storedDiscountAmount = localStorage.getItem("discountAmount"); // Puxa o valor do desconto do localStorage
    setDiscountAmount(
      storedDiscountAmount ? parseFloat(storedDiscountAmount) : 0
    ); // Atualiza o estado do desconto
    // console.log("Cupom armazenado:", storedCoupon); // Log para verificar o cupom armazenado
    if (storedCoupon) {
      setDiscountCoupon(storedCoupon); // Atualiza o estado com o cupom encontrado
      if (storedCoupon === "55") {
        // Aplica o desconto de 55%
        setDiscountCoupon("55");
        // console.log("Desconto de 55% aplicado."); // Log para verificar o desconto
      }
    }
  }, []); // Executa apenas uma vez ao montar o componente

  const handleCardNumberChange = (e) => {
    setCardNumber(e.target.value);
  };

  const handlePaymentMethodChange = (method) => {
    setActivePaymentMethod(method);
    if (method === "PIX") {
      setShowPixStep(true);
      setShowCardStep(false); // Oculta o passo do cartão
    } else if (method === "CARTAO") {
      setShowCardStep(true); // Exibe o passo do cartão
      setShowPixStep(false); // Oculta o passo do PIX
    }
  };

  const handleChangePaymentMethod = () => {
    setShowPixStep(false);
    setShowCardStep(false); // Oculta o passo do cartão
    setActivePaymentMethod("");
  };

  const handleFinalizePurchase = async () => {
    setLoading(true); // Inicia o loading
    // Se o método de pagamento for PIX, redireciona imediatamente com os dados do carrinho
    if (activePaymentMethod === "PIX") {
      const cart = JSON.parse(localStorage.getItem("cart")) || []; // Puxa o carrinho do localStorage
      const cartItems = cart.map((item) => ({
        id: item.id, // Supondo que cada item tenha um campo 'id'
        quantity: item.quantity,
      }));

      navigate({
        pathname: "/payment-pix",
        state: { cartItems }, // Enviando os itens do carrinho para a página payment-pix
      });
      return; // Interrompe a execução da função
    }

    const purchaseData = {
      user_id: localStorage.getItem("userId"),
      card_number: cardNumber,
      card_holder_name: cardHolderName,
      card_expiration_date: cardExpirationDate,
      card_cvv: cardCvv,
      parcels: parcels,
      parcel_value: (
        (cartSummary.total +
          shippingCost -
          (discountAmount > 0
            ? (cartSummary.total + shippingCost) * (discountAmount / 100)
            : 0)) /
        parcels
      ).toFixed(2),
      utm_campaign: localStorage.getItem("utm_campaign"),
      utm_content: localStorage.getItem("utm_content"),
      utm_medium: localStorage.getItem("utm_medium"),
      utm_source: localStorage.getItem("utm_source"),
      utm_term: localStorage.getItem("utm_term"),
    };

    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/orderCard`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(purchaseData),
        }
      );

      const result = await response.json(); // Pega a resposta do backend
      console.log("Resposta do backend:", result); // Exibe a resposta no console

      if (response.status === 400) {
        console.log("Código 400 recebido, abrindo modalRefused.");
        setModalIsOpen(true); // Abre o modal
        setErrorMessage(result.message); // Atualiza a mensagem de erro com a resposta do backend
        localStorage.setItem("paymentMethod", "cartao");
        return; // Interrompe a execução da função
      }

      if (!response.ok) {
        throw new Error("Erro ao finalizar a compra");
      }

      console.log("Compra finalizada com sucesso:", result);

      // Redireciona para a página de sucesso se o método de pagamento for CARTAO
      if (activePaymentMethod === "CARTAO") {
        navigate("/success");
      }
    } catch (error) {
      console.error("Erro:", error);
      // Aqui você pode mostrar uma mensagem de erro para o usuário
    } finally {
      setLoading(false); // Finaliza o loading
    }
  };

  const handleCardExpirationDateChange = (e) => {
    setCardExpirationDate(e.target.value);
  };

  const handleCardCvvChange = (e) => {
    setCardCvv(e.target.value);
  };

  const handleParcelsChange = (e) => {
    setParcels(e.target.value);
  };

  const handleCardHolderNameChange = (e) => {
    setCardHolderName(e.target.value);
  };

  return (
    <div className="payment-form">
      <ModalRefused
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        message={errorMessage}
      />
      {showPixStep ? (
        <div className="pix-step">
          <h2>Você escolheu a forma de pagamento PIX.</h2>
          <p>É simples e seguro:</p>
          <ol>
            <li>
              Clique em “Gerar código Pix” para ser direcionado ao pagamento
            </li>
            <li>
              Acesse o aplicativo da sua instituição financeira, leia o QR Code
              ou copie e cole o link
            </li>
            <li>Confira as informações de pagamento e pronto!</li>
            <li>
              Ah, se houver descontos ligados ao pagamento via Pix, você irá
              conferi-los após a geração do código.
            </li>
          </ol>
          <button
            className="finalize-purchase"
            onClick={handleFinalizePurchase}
            disabled={loading}
          >
            {loading ? "CARREGANDO..." : "FINALIZAR COMPRA"}
          </button>
          <button
            className="change-payment-method"
            onClick={handleChangePaymentMethod}
          >
            ALTERAR FORMA DE PAGAMENTO
          </button>
        </div>
      ) : showCardStep ? ( // Condicional para exibir o passo do cartão
        <div className="card-step">
          <h2>Dados do Cartão de Crédito</h2>
          <label>Número do cartão</label>
          <MaskedInput
            mask={[
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            placeholder="1234 5678 9012 3456"
            onChange={handleCardNumberChange}
          />
          <label>Data de validade</label>
          <MaskedInput
            mask={[/\d/, /\d/, "/", /\d/, /\d/]}
            placeholder="MM/AA"
            onChange={handleCardExpirationDateChange}
          />
          <label>CVC / CVV</label>
          <MaskedInput
            mask={[/\d/, /\d/, /\d/]}
            placeholder="3 dígitos"
            onChange={handleCardCvvChange}
          />
          <label>Nome no cartão</label>
          <input
            type="text"
            placeholder="J. Smith"
            onChange={handleCardHolderNameChange}
          />
          <label>Opções de Parcelamento</label>
          <select onChange={handleParcelsChange}>
            <option>
              1x R${(cartSummary.total + shippingCost).toFixed(2)}
            </option>
            <option>
              2x R$
              {(((cartSummary.total + shippingCost) * 1.15) / 2).toFixed(2)}
            </option>
            <option>
              3x R$
              {(((cartSummary.total + shippingCost) * 1.15) / 3).toFixed(2)}
            </option>
          </select>
          <button
            className="finalize-purchase"
            onClick={handleFinalizePurchase}
            disabled={loading}
          >
            {loading ? "CARREGANDO..." : "FINALIZAR COMPRA"}
          </button>
          <button
            className="change-payment-method"
            onClick={handleChangePaymentMethod}
          >
            ALTERAR FORMA DE PAGAMENTO
          </button>
        </div>
      ) : (
        <>
          <h2>Como você prefere pagar?</h2>
          <div className="payment-options">
            <button
              className={`payment-button ${
                activePaymentMethod === "PIX" ? "active" : ""
              }`}
              onClick={() => {
                localStorage.setItem("paymentMethod", "pix");
                handlePaymentMethodChange("PIX");
              }}
            >
              PIX (10% de desconto)
            </button>
            <button
              className={`payment-button ${
                activePaymentMethod === "CARTAO" ? "active" : ""
              }`}
              onClick={() => {
                localStorage.setItem("paymentMethod", "cartao");
                handlePaymentMethodChange("CARTAO");
              }}
            >
              Cartão de Crédito
            </button>
          </div>
          <div className="card-input">
            <label>DIGITE O CÓDIGO DO DESCONTO</label>
            <div className="input-group">
              <input
                type="text"
                value={discountCoupon} // Atualiza o valor do input para o cupom de desconto
                readOnly // Torna o campo somente leitura
                placeholder="Ex. ABC123"
              />
              <button className="add-button">ADICIONAR</button>
              <button className="plus-button">+</button>
            </div>
          </div>
          <div className="summary">
            <h3>RESUMO</h3>
            <div className="summary-items-payment">
              <p className="summary-item-total">
                <span>{cartSummary.length} PRODUTO(S)</span>
                <span>
                  R${(Math.floor(cartSummary.total * 100) / 100).toFixed(2)}
                </span>
              </p>
              {discountAmount > 0 && ( // Verifica se o cupom de desconto existe
                <p>
                  Desconto:{" "}
                  <span>
                    R$
                    {(
                      Math.floor(
                        (cartSummary.total + shippingCost) *
                          100 *
                          (discountAmount === "55"
                            ? 0.55
                            : discountAmount > 0
                            ? discountAmount / 100
                            : 0) || 0
                      ) / 100
                    ).toFixed(2)}
                    ({discountAmount}%)
                  </span>
                </p>
              )}

              <p>
                FRETE{" "}
                <span>
                  R${(Math.floor(shippingCost * 100) / 100).toFixed(2)}
                </span>
              </p>
              <p>
                TOTAL A PAGAR{" "}
                <span className="total-amount">
                  R$
                  {(
                    Math.floor(
                      ((cartSummary.total || 0) +
                        (shippingCost || 0) -
                        (discountAmount > 0 // Verifica se há um desconto válido
                          ? (cartSummary.total + shippingCost) *
                            (discountAmount / 100) // Aplica o desconto corretamente ao total do produto e frete
                          : 0)) *
                        100
                    ) / 100
                  ).toFixed(2)}
                </span>
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentForm;
