// src/components/CartModal/CartModal.jsx

import React from "react";
import { useNavigate } from "react-router-dom"; // Importando useHistory
import "./style.css"; // Certifique-se de que o CSS está importado

const CartModal = ({ isOpen, onRequestClose }) => {
  const navigate = useNavigate(); // Hook para acessar o histórico

  if (!isOpen) return null; // Não renderiza nada se o modal não estiver aberto

  const handleCloseModal = () => {
    onRequestClose();
  };

  const handleOverlayClick = (e) => {
    // Fecha o modal se o clique for na sobreposição
    if (e.target.className === "cart-modal-overlay") {
      handleCloseModal();
    }
  };

  const handleFinalizePurchase = () => {
    handleCloseModal(); // Fecha o modal
    navigate("/carrinho"); // Redireciona para a página do carrinho
  };

  return (
    <div className="cart-modal-overlay" onClick={handleOverlayClick}>
      <div className="cart-modal-content">
        <button className="cart-close-button" onClick={handleCloseModal}>
          &times; {/* Símbolo de fechar */}
        </button>
        <h2 className="cart-modal-title">Produto Adicionado ao Carrinho</h2>
        <p className="cart-modal-message">
          Você gostaria de finalizar a compra ou continuar comprando?
        </p>
        <div className="cart-modal-button-container">
          <button
            className="cart-modal-button"
            onClick={handleFinalizePurchase}
          >
            Finalizar Compra
          </button>
          <button className="cart-modal-button" onClick={handleCloseModal}>
            Continuar Comprando
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartModal;
