import React, { useRef, useEffect, useState } from "react";
import BannerImgLines from "../BannerImgLines/BannerImgLines";
import "./style.css";

const BannerImgList = () => {
  const containerRef = useRef(null);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/banner-imgs-lines`
        );
        const data = await response.json();
        setBanners(data);
      } catch (error) {
        console.error("Erro ao buscar banners:", error);
      }
    };

    fetchBanners();
  }, []);

  const handleScroll = (direction) => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const newScrollPosition =
        containerRef.current.scrollLeft +
        (direction === "left" ? -containerWidth : containerWidth);

      containerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="carousel-container-bannerlistLines">
      <p className="title-bannerlist-lines">Nossas linhas</p>
      <p className="subtitle-bannerlist-lines">
        Experimente também essas novas sensações
      </p>
      <button
        className="carousel-button carousel-button-left"
        onClick={() => handleScroll("left")}
        aria-label="Anterior"
      >
        <span>‹</span>
      </button>

      <div className="carousel-wrapper all" ref={containerRef}>
        <div className="carousel-track">
          {Array.isArray(banners) && banners.length > 0 ? (
            banners.map((banner) => (
              <BannerImgLines key={banner.id} {...banner} />
            ))
          ) : (
            <p>Nenhum produto disponível.</p>
          )}
        </div>
      </div>

      <button
        className="carousel-button carousel-button-right"
        onClick={() => handleScroll("right")}
        aria-label="Próximo"
      >
        <span>›</span>
      </button>
    </div>
  );
};

export default BannerImgList;
