import React, { useState } from "react";
import "./styles.css";
import logo from "../../assets/logo.svg";
import felizhoje from "../../assets/FelizHoje.png";

const Information = () => {
  const [expandido, setExpandido] = useState(false);

  const toggleTexto = () => {
    setExpandido(!expandido);
  };

  return (
    <>
      <div className="information-header" onClick={toggleTexto}>
        <div className="more-info-button">
          <h2 className="information-title">MAIS INFORMAÇÕES</h2>
          <span className={`seta ${expandido ? "expandido" : ""}`}>▼</span>
        </div>
      </div>
      <div className="information-container">
        <div className="logo-information">
          <img
            src={logo}
            alt="Logo CacauShow"
            className="cacau-logo-information dark-logo"
          />
        </div>

        {expandido && (
          <div className="information-texto">
            <p>
              A Cacau Show se compromete em utilizar ovos de galinhas livres de
              gaiolas em todas as suas operações. Estamos trabalhando para
              garantir essa transição até 2025. As imagens expostas neste site
              são meramente ilustrativas.
            </p>

            <p>
              Preços apresentados na loja virtual podem variar de acordo com a
              região. Informações sobre preços, prazos de validade, estoque e
              origem podem ser obtidas diretamente nas lojas. Consulte a loja
              para verificar disponibilidade.
            </p>

            <p>
              Preços, estoques, informações e condições disponíveis no site
              estão sujeitos a alterações sem aviso prévio. *Preços e produtos
              do Choco-Outlet são válidos somente para compras realizadas na
              loja virtual sujeito a disponibilidade de estoque.
            </p>
          </div>
        )}

        <img src={felizhoje} alt="Feliz Hoje" className="feliz-hoje" />
      </div>
    </>
  );
};

export default Information;
