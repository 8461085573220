import React from "react";
import "./styles.css"; // Importando o CSS

const Footer = () => {
  return (
    <footer className="footer">
      <p>
        © Copyright 2023. Todos os direitos reservados. | Cacau Comércio
        Eletrônico LTDA 32.143.933/0002–05. | Endereço: Avenida Helio Ossamu
        Daikuara, 1445 Bairro Jardim Vista Alegre Município Embu das Artes - SP
        CEP: 06807-000
      </p>
    </footer>
  );
};

export default Footer;
