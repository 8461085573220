import React, { useEffect } from "react";
import HeaderLogin from "./components/HeaderLogin/HeaderLogin.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import LoginForm from "./components/Login/Login.jsx";
const Login = ({ children }) => {
  useEffect(() => {
    document.title = "Login";
  }, []);

  return (
    <div>
      <HeaderLogin />
      <LoginForm />
      <Footer />
    </div>
  );
};

export default Login;
