import React, { useEffect } from "react";
import HeaderLogin from "../Login/components/HeaderLogin/HeaderLogin.jsx";
import DeliveryAddress from "./components/AddressForm/AddressForm.jsx";
import Footer from "../../components/Footer/Footer.jsx";

const Checkout = ({ children }) => {
  useEffect(() => {
    document.title = "Checkout";
  }, []);

  return (
    <div>
      <HeaderLogin />
      <DeliveryAddress />
      <Footer />
    </div>
  );
};

export default Checkout;
