// src/Pages/thanks/components/ThanksForm/ThanksForm.jsx

import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./style.css"; // Importando o arquivo CSS
import QRCode from "react-qr-code";

const ThanksForm = () => {
  const location = useLocation();
  const cartItems = localStorage.getItem("cart");
  const cartItems2 = JSON.parse(cartItems) || [];

  // Calcular o total do carrinho
  const total = cartItems2.reduce((acc, item) => {
    return acc + parseFloat(item.price) * item.quantity;
  }, 0);

  const productName = cartItems2.length > 0 ? cartItems2[0].name : ""; // Nome do produto
  const productQuantity = cartItems2.length > 0 ? cartItems2[0].quantity : 0; // Quantidade do produto
  const [shippingCost, setShippingCost] = useState(0); // Inicializa o custo de envio
  const [cartSummary, setCartSummary] = useState({ items: 0, total: 0 });
  const hasPurchased = useRef(false); // Usando useRef para controlar a compra
  const paymentMethod =
    location.state?.paymentMethod || localStorage.getItem("paymentMethod"); // Obtém o método de pagamento

  const discountAmount = 55; // Define o valor fixo de desconto como 55

  localStorage.setItem("discountAmount", discountAmount); // Salva o valor do desconto no localStorage

  const [orderNumber, setOrderNumber] = useState(() => {
    const storedOrderNumber = localStorage.getItem("orderNumber");
    return storedOrderNumber
      ? storedOrderNumber
      : `CS-${Math.floor(Math.random() * 100000000)}`;
  });

  useEffect(() => {
    localStorage.setItem("orderNumber", orderNumber);
  }, [orderNumber]);

  useEffect(() => {
    if (cartItems.length > 0) {
      setCartSummary({
        length: cartItems.length,
        total: 0,
      });
    }
  }, [cartItems]);

  const [deliveryDays, setDeliveryDays] = useState(0); // Estado para armazenar os dias de entrega

  useEffect(() => {
    const deliveryOption = localStorage.getItem("deliveryOption");
    let shipping = 0.0;
    let days = 0;

    if (deliveryOption === "Total Express") {
      shipping = 0.0;
      days = 7; // 10 dias para Total Express
    } else if (deliveryOption === "Correios PAC") {
      shipping = 10.0;
      days = 5; // 5 dias para Correios PAC
    } else if (deliveryOption === "Correios Sedex") {
      shipping = 20.0;
      days = 1; // 1 dia para Correios Sedex
    }

    setShippingCost(shipping);
    setDeliveryDays(days); // Atualiza os dias de entrega
  }, []);

  useEffect(() => {
    if (!hasPurchased.current) {
      // Verifica se a compra já foi realizada
      handlePurchase();
      hasPurchased.current = true; // Marca a compra como realizada
    }
  }, []); // Executa apenas uma vez ao montar o componente

  useEffect(() => {
    const discountCoupon = localStorage.getItem("discount_coupon");
    if (!discountCoupon) {
      const newCoupon = `CUPOM-${Math.floor(Math.random() * 10000)}`; // Gera um novo cupom
      localStorage.setItem("discount_coupon", newCoupon); // Salva o novo cupom no localStorage
    }
  }, []); // Executa apenas uma vez ao montar o componente

  const handlePurchase = async () => {
    const cart = cartItems2.map(({ id, quantity }) => ({ id, quantity })); // Simplifica a criação do cart

    const shipping =
      localStorage.getItem("deliveryOption") === "Total Express"
        ? 0.0
        : localStorage.getItem("deliveryOption") === "Correios PAC"
        ? 10.0
        : localStorage.getItem("deliveryOption") === "Correios Sedex"
        ? 20.0
        : 0;

    const purchaseData = {
      user_id: localStorage.getItem("userId"),
      cart,
      shipping: shipping,
      total: total + shipping - discountAmount,
      payment_method: paymentMethod,
      coupon: localStorage.getItem("coupon") || false,
    };

    const response = await fetch(`${import.meta.env.VITE_API_URL}/orderPix`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(purchaseData),
    });

    const data = await response.json();
    localStorage.setItem("pix_code", data.pix_code);

    if (!response.ok) {
      throw new Error("Erro ao processar a compra");
    }

    // Apagar o carrinho do localStorage após a compra
    localStorage.removeItem("cart");

    // Tratar a resposta conforme necessário
  };

  return (
    <>
      <div className="paymentpix-header">
        <h2>Obrigado pelo seu pedido.</h2>

        <div className="paymentpix-confirmation">
          <div className="paymentpix-details">
            {localStorage.getItem("discount_coupon") ? (
              <p>
                Cupom de desconto:{" "}
                <strong>{localStorage.getItem("discount_coupon")}</strong>
              </p>
            ) : (
              <p>Gerando cupom de desconto, por favor aguarde...</p>
            )}
          </div>

          <h4>Número do Pedido: {orderNumber}</h4>

          <div className="promo-section">
            <h3>Aproveite 55% de desconto!</h3>
            <p>
              Use o cupom{" "}
              <strong>{localStorage.getItem("discount_coupon")}</strong> em
              compras acima de R$199,00 e garanta seu desconto hoje mesmo!
            </p>
            <button
              className="promo-button"
              onClick={() => {
                const coupon = localStorage.getItem("discount_coupon");
                navigator.clipboard.writeText(coupon);
                localStorage.setItem("copied_coupon", coupon); // Salva o cupom copiado no localStorage
                localStorage.removeItem("cart"); // Apaga o carrinho do localStorage
                window.location.href = "/"; // Redireciona para a página inicial
              }}
            >
              Copiar Cupom
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThanksForm;
