import { useState, useEffect } from "react";

function useCartListener() {
  const [cartQuantity, setCartQuantity] = useState(0);

  const calculateCartQuantity = () => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const totalQuantity = cart.reduce((acc, item) => {
      return acc + (item.quantity || 0);
    }, 0);
    setCartQuantity(totalQuantity);
  };

  useEffect(() => {
    calculateCartQuantity();

    const handleStorageChange = (event) => {
      if (event.key === "cart") {
        calculateCartQuantity();
      }
    };

    const handleCartUpdate = () => calculateCartQuantity();

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("cartUpdated", handleCartUpdate);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("cartUpdated", handleCartUpdate);
    };
  }, []);

  return cartQuantity;
}

export default useCartListener; 