// src/Pages/components/ProductCard/ProductCard.jsx

import React, { useState } from "react";
import CartModal from "../../../../components/CartModal/CartModal";
import "./styles.css"; // Adicione um arquivo CSS para estilização

const formatCurrency = (value) => {
  if (typeof value !== "number") {
  }
  const truncatedValue = Math.trunc(value * 100) / 100; // Trunca o valor para duas casas decimais
  return `R$ ${truncatedValue.toFixed(2).replace(".", ",")}`; // Formata para R$ e substitui ponto por vírgula
};

const ProductCard = ({ product }) => {
  const [quantity, setQuantity] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar o modal

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleAddToCart = () => {
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];

    const existingProductIndex = cartItems.findIndex(
      (item) => item.id === product.id
    );

    if (existingProductIndex > -1) {
      // Se o produto já existe, atualiza a quantidade
      cartItems[existingProductIndex].quantity += quantity;
    } else {
      // Se não existe, adiciona o novo produto
      cartItems.push({ ...product, quantity });
    }

    localStorage.setItem("cart", JSON.stringify(cartItems));

    // Abre o modal após adicionar ao carrinho
    setIsModalOpen(true);

    // Dispara um evento personalizado para atualizar na mesma aba
    const event = new Event("cartUpdated");
    window.dispatchEvent(event);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="product-card">
      <h1 className="product-title">{product.name}</h1>
      <p className="product-sku">{product.sku}</p>
      <p className="product-price-old">
        {formatCurrency(product.second_price)}
      </p>
      <div className="price-quantity-container">
        <p className="product-price-new">
          {formatCurrency(product.price)} <span className="unit">/un</span>
        </p>

        {/* Seletor de Quantidade */}
        <div className="quantity-selector">
          <button onClick={handleDecrement}>-</button>
          <span>{quantity}</span>
          <button onClick={handleIncrement}>+</button>
        </div>
      </div>

      {/* Botão Adicionar ao Carrinho */}
      <button
        className="add-to-cart-button"
        onClick={handleAddToCart} // Chama a função que adiciona ao carrinho e abre o modal
      >
        ADICIONAR AO CARRINHO
        <i className="fas fa-shopping-cart"></i>
      </button>

      {/* Modal de Carrinho */}
      <CartModal isOpen={isModalOpen} onRequestClose={closeModal} />

      {/* Botão de Cacaus */}
      <div className="cacao-button">
        <span>{product.quantityAvailable} CACAUS</span>
        <span className="info-icon">i</span>
      </div>

      {/* Badge Chocofriday */}
      <div className="chocofriday-badge">CHOCOFRIDAY</div>
    </div>
  );
};

export default ProductCard;
