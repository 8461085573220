// src/Pages/Payments/components/PaymentPix/PaymentPix.jsx

import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./style.css"; // Importando o arquivo CSS
import QRCode from "react-qr-code";
import loadingGif from "../../../../assets/loading.gif"; // Importando o GIF de carregamento

const PaymentPix = () => {
  useEffect(() => {
    document.title = "Pagamento Pix";
  }, []);

  const location = useLocation();
  const cartItems = localStorage.getItem("cart");
  const cartItems2 = JSON.parse(cartItems) || [];

  // Calcular o total do carrinho
  const total = cartItems2.reduce((acc, item) => {
    return acc + parseFloat(item.price) * item.quantity;
  }, 0);

  const productName = cartItems2.length > 0 ? cartItems2[0].name : ""; // Nome do produto
  const productQuantity = cartItems2.length > 0 ? cartItems2[0].quantity : 0; // Quantidade do produto
  const [shippingCost, setShippingCost] = useState(0); // Inicializa o custo de envio
  const [cartSummary, setCartSummary] = useState({ items: 0, total: 0 });
  const hasPurchased = useRef(false); // Usando useRef para controlar a compra
  const [loading, setLoading] = useState(false); // Estado para controle de carregamento
  const paymentMethod =
    location.state?.paymentMethod || localStorage.getItem("paymentMethod"); // Obtém o método de pagamento

  const discountCoupon = localStorage.getItem("discount_coupon"); // Obtém o cupom de desconto do localStorage

  const discountAmount =
    paymentMethod === "pix"
      ? total * 0.1 // Desconto de 10% para PIX
      : paymentMethod === "cartao"
      ? total * 0.2 // Desconto de 20% para Cartão
      : 0; // Sem desconto se nenhum método for encontrado

  const couponDiscount =
    discountCoupon && discountAmount > 0
      ? 60.5 // Defina o valor fixo do desconto correto
      : 0; // Sem desconto adicional se não houver cupom

  const totalToPay = total + shippingCost - discountAmount - couponDiscount;

  const totalWithDiscount = shippingCost + total - couponDiscount;

  const [orderNumber, setOrderNumber] = useState(() => {
    const storedOrderNumber = localStorage.getItem("orderNumber");
    return storedOrderNumber
      ? storedOrderNumber
      : `CS-${Math.floor(Math.random() * 100000000)}`;
  });

  useEffect(() => {
    localStorage.setItem("orderNumber", orderNumber);
  }, [orderNumber]);

  useEffect(() => {
    if (cartItems.length > 0) {
      setCartSummary({
        length: cartItems.length,
        total: 0,
      });
    }
  }, [cartItems]);

  const [deliveryDays, setDeliveryDays] = useState(0); // Estado para armazenar os dias de entrega

  useEffect(() => {
    const deliveryOption = localStorage.getItem("deliveryOption");
    let shipping = 0.0;
    let days = 0;

    if (deliveryOption === "Total Express") {
      shipping = 0.0;
      days = 7; // 10 dias para Total Express
    } else if (deliveryOption === "Correios PAC") {
      shipping = 10.0;
      days = 5; // 5 dias para Correios PAC
    } else if (deliveryOption === "Correios Sedex") {
      shipping = 20.0;
      days = 1; // 1 dia para Correios Sedex
    }

    setShippingCost(shipping);
    setDeliveryDays(days); // Atualiza os dias de entrega
  }, []);

  const [isTotalSent, setIsTotalSent] = useState(false); // Estado para rastrear se o total foi enviado

  useEffect(() => {
    if (!hasPurchased.current) {
      // Verifica se a compra já foi realizada
      handlePurchase();
      hasPurchased.current = true; // Marca a compra como realizada
    }
  }, []); // Executa apenas uma vez ao montar o componente

  const handlePurchase = async () => {
    setLoading(true); // Inicia o carregamento

    try {
      const cartItems2 = JSON.parse(cartItems) || [];
      if (!Array.isArray(cartItems2) || cartItems2.length === 0) {
        console.error("Nenhum item no carrinho para processar a compra.");
        return;
      }

      const cart = cartItems2.map(({ id, quantity }) => ({ id, quantity })); // Simplifica a criação do cart

      // Verifica o custo de envio novamente
      const shipping =
        localStorage.getItem("deliveryOption") === "Total Express"
          ? 0.0
          : localStorage.getItem("deliveryOption") === "Correios PAC"
          ? 10.0
          : localStorage.getItem("deliveryOption") === "Correios Sedex"
          ? 20.0
          : 0;

      // Atualiza o custo de envio
      setShippingCost(shipping); // Atualiza o estado do custo de envio

      // Total a Pagar (usando o valor correto)
      const totalWithDiscount =
        total + shipping - discountAmount - couponDiscount;

      const purchaseData = {
        user_id: localStorage.getItem("userId"),
        cart,
        total: totalWithDiscount, // Usando o total correto
        payment_method: paymentMethod,
        coupon: localStorage.getItem("coupon") || false,
        discountAmount: discountAmount, // Adicionando o discountAmount
        couponDiscount: couponDiscount, // Adicionando o couponDiscount
        utm_campaign: localStorage.getItem("utm_campaign"), // Adiciona utm_campaign
        utm_content: localStorage.getItem("utm_content"), // Adiciona utm_content
        utm_medium: localStorage.getItem("utm_medium"), // Adiciona utm_medium
        utm_source: localStorage.getItem("utm_source"), // Adiciona utm_source
        utm_term: localStorage.getItem("utm_term"), // Adiciona utm_term
      };

      // Adicionando log para verificar os descontos
      // console.log("Dados da compra:", {
      //   total: totalWithDiscount,
      //   discountAmount,
      //   couponDiscount,
      //   purchaseData,
      // });

      // Verifica se o total a pagar é válido antes de enviar
      if (totalWithDiscount > 0) {
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/orderPix`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(purchaseData),
          }
        );

        const data = await response.json();
        localStorage.setItem("pix_code", data.pix_code);

        if (!response.ok) {
          throw new Error("Erro ao processar a compra");
        }

        // Tratar a resposta conforme necessário
      } else {
        console.error("Total a pagar inválido:", totalWithDiscount);
      }
    } catch (error) {
      console.error("Erro:", error);
    } finally {
      setLoading(false); // Finaliza o carregamento
    }
  };

  return (
    <>
      <div className="paymentpix-header">
        <h2>Obrigado pelo seu pedido.</h2>
        <p>
          Você receberá uma confirmação por e-mail em breve em{" "}
          <strong>{localStorage.getItem("userEmail")}</strong>
        </p>
        <div className="paymentpix-confirmation">
          <div className="paymentpix-details">
            <p>
              Abrir o app com sua chave PIX cadastrada, escolha Pagar com PIX e
              escaneie o QR Code ou copie e cole o código.
            </p>
            {loading ? ( // Verifica se está carregando
              <>
                <p className="highlight-message">
                  Gerando código PIX, por favor aguarde...
                </p>{" "}
                <img src={loadingGif} alt="Carregando..." />
              </>
            ) : localStorage.getItem("pix_code") ? (
              <>
                <QRCode value={localStorage.getItem("pix_code")} />
                <button
                  className="paymentpix-copy-button"
                  onClick={() =>
                    navigator.clipboard.writeText(
                      localStorage.getItem("pix_code")
                    )
                  }
                >
                  COPIAR CÓDIGO
                </button>
              </>
            ) : (
              <p>Gerando código PIX, por favor aguarde...</p>
            )}
          </div>
          <p>
            Se o pagamento não for confirmado, não se preocupe. O pedido será
            cancelado automaticamente.
          </p>
          <h3>Número do Pedido: {orderNumber}</h3>
          <p>Data do pedido: {new Date().toLocaleDateString()}</p>
          <div>
            <h4>Detalhes do Pedido:</h4>
            {cartItems2.map((item, index) => (
              <p key={index}>
                {item.quantity} x {item.name} R${" "}
                {Math.floor(parseFloat(item.price) * 100) / 100}
              </p>
            ))}
            {discountAmount > 0 && (
              <p>Cupom de desconto: R$ {discountAmount.toFixed(2)}</p>
            )}
            {couponDiscount > 0 &&
              discountCoupon && ( // Verifica se há desconto adicional e se o cupom existe
                <p>Desconto adicional (55%): R$ {couponDiscount.toFixed(2)}</p>
              )}
            <p>
              Frete: R${shippingCost.toFixed(2)} (
              {localStorage.getItem("deliveryOption")})
            </p>
            <h4>
              Total a Pagar: R$
              {totalToPay.toFixed(2)}
            </h4>
          </div>
          <h4>Previsão de Entrega: {deliveryDays} dias</h4>
          <p>
            Data de Previsão:{" "}
            {new Date(
              Date.now() + deliveryDays * 24 * 60 * 60 * 1000
            ).toLocaleDateString()}
          </p>
          <div className="paymentpix-contact-info">
            <p>{localStorage.getItem("userName")} |</p>
            <p>{localStorage.getItem("userPhone")}</p>
            <p>{localStorage.getItem("userAddress")}</p>
            <p>{localStorage.getItem("userCity")}</p>
            <p>{localStorage.getItem("userZipCode")}</p>
          </div>
          {localStorage.getItem("coupon") && (
            <p>Cupom aplicado: {localStorage.getItem("coupon")}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentPix;
