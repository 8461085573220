import React, { useEffect } from "react";
import { useState } from "react";
import reactLogo from "./assets/react.svg";
import viteLogo from "/vite.svg";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "font-awesome/css/font-awesome.min.css";
import Product from "./Pages/Product/Product.jsx";
import Cart from "./Pages/Cart/Cart";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Regisrer/Register";
import Checkout from "./Pages/Checkout/checkout";
import Payments from "./Pages/Payments/Payments";
import PaymentPix from "./Pages/Payments/PixPayment";
import { CartProvider } from "./contexts/CartContext";
import Category from "./Pages/Category/Category";
import SearchCategory from "./Pages/SearchCategory/SearchCategory";
import Thanks from "./Pages/thanks/thanks";
// import { Helmet } from "react-helmet";

function App() {
  const [count, setCount] = useState(0);

  document.title = "CacauSow";

  return (
    <CartProvider>
      <Router>
        {/* <Helmet> */}
        {/* <title>CacauSow</title> */}
        {/* </Helmet> */}
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="/produtos/:id" element={<Product />} />
          <Route path="/carrinho" element={<Cart />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/checkout/:orderId" element={<Checkout />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/payment-pix" element={<PaymentPix />} />
          <Route path="/category/:id" element={<Category />} />
          <Route path="/search-category" element={<SearchCategory />} />
          <Route path="/thanks" element={<Thanks />} />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
