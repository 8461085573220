import React from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";

function ModalRefused({ isOpen, onRequestClose, errorMessage }) {
  const navigate = useNavigate();

  if (!isOpen) return null; // Não renderiza o modal se não estiver aberto

  const handleOverlayClick = (event) => {
    // Verifica se o clique foi na sobreposição (overlay)
    if (event.target === event.currentTarget) {
      onRequestClose(); // Fecha o modal
    }
  };

  const handlePixPayment = () => {
    navigate("/payment-pix");
  };

  return (
    <div className="modal-overlay-refused" onClick={handleOverlayClick}>
      <div className="modal-content-refused">
        <h2 className="modal-title-refused">Pagamento Recusado</h2>
        <p>
          {errorMessage ||
            "Seu pagamento foi recusado. Por favor, tente outro cartão."}
        </p>
        <button className="modal-button-refused" onClick={handlePixPayment}>
          Pagar com Pix (20% de desconto)
        </button>
        <button className="modal-button-refused" onClick={onRequestClose}>
          Tentar Novamente
        </button>
      </div>
    </div>
  );
}

export default ModalRefused;
