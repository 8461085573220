import React from "react";
import "./styles.css";

function BannerImgLines({ image }) {
  return (
    <div
      className="slick-slide-lines slick-current-lines slick-active-lines"
      data-slick-index="0"
      aria-hidden="false"
    >
      <div className="image-viewBanner-lines">
        <picture>
          <img
            src={image}
            alt="Momento Chocolatinho"
            style={{ width: "100%" }}
          />
        </picture>
        <div className="overlay"></div>
      </div>
    </div>
  );
}

export default BannerImgLines;
