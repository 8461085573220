import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalAddress from "../ModalAddress/ModalAddress";
import {
  faShoppingBag,
  faSearch,
  faMapMarkerAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { PiShoppingCartLight } from "react-icons/pi";
import useCartListener from "../../hooks/useCartListener";
import Sidebar from "../Sidebar/Sidebar";
import "./styles.css";
import logo from "../../assets/logo.svg";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const cartQuantity = useCartListener();
  const [isModalAddressOpen, setIsModalAddressOpen] = useState(false);
  const [city, setCity] = useState(localStorage.getItem("city") || "Cajamar");
  const [state, setState] = useState(localStorage.getItem("state") || "SP");
  const navigate = useNavigate();
  const [clientName, setClientName] = useState(
    localStorage.getItem("userData")
      ? `${JSON.parse(localStorage.getItem("userData")).first_name || ""} ${
          JSON.parse(localStorage.getItem("userData")).nome || ""
        }`.trim()
      : "Entrar"
  );

  useEffect(() => {
    localStorage.setItem("city", city);
    localStorage.setItem("state", state);
  }, [city, state]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const openModalAddress = () => {
    setIsModalAddressOpen(true);
  };

  const closeModalAddress = () => {
    setIsModalAddressOpen(false);
  };

  const updateLocation = (newCity, newState) => {
    setCity(newCity);
    setState(newState);
  };

  const buscarProduto = async (produto) => {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/products/search`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: produto,
        }),
      }
    );
    const data = await response.json();
    console.log(data); // Aqui você pode manipular os dados retornados

    navigate("/search-category", { state: { products: data } });
  };

  return (
    <header className="header">
      <div className="top-bar">
        <a className="logo-home" href="/" title="Cacau Show Home">
          <img className="logo" src={logo} alt="Cacau Show" />
        </a>
        <div className="d-flex align-items-center">
          <div className="minicart" data-action-url="/">
            <a
              className="minicart-link"
              href="/carrinho"
              title={`carrinho ${cartQuantity} Itens`}
              aria-label={`carrinho ${cartQuantity} Itens`}
              aria-haspopup="true"
            >
              <PiShoppingCartLight className="minicart-icon" />
              <span className="minicart-quantity">{cartQuantity || 0}</span>
            </a>
          </div>
          <button
            className="navbar-toggler"
            onClick={toggleMenu}
            aria-expanded={menuOpen}
            aria-label="Toggle navigation"
          >
            ☰
          </button>
        </div>
      </div>

      <div className="search-container">
        <input
          className="search-field"
          type="text"
          placeholder="Buscar Produto"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              buscarProduto(e.target.value);
            }
          }}
        />
        <button
          className="search-button"
          aria-label="Buscar"
          onClick={() =>
            buscarProduto(document.querySelector(".search-field").value)
          }
        >
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
        </button>
      </div>

      <div className="location-container">
        <div className="location">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="location-icon" />
          <span className="location-text">
            {city} - {state}
          </span>
          <button className="change-location" onClick={openModalAddress}>
            ALTERAR
          </button>
        </div>
        {clientName !== "Entrar" ? (
          <div className="welcome-message">
            <div>
              Bem-vindo,
              <br />
            </div>
            <strong>{clientName}</strong>!
          </div>
        ) : (
          <button className="login-button" onClick={() => navigate("/login")}>
            <FontAwesomeIcon icon={faUser} className="login-icon" />
            {clientName}
          </button>
        )}
      </div>

      {menuOpen && (
        <>
          <nav className="mobile-menu">
            <ul></ul>
          </nav>
          <Sidebar />
        </>
      )}

      <ModalAddress
        isOpen={isModalAddressOpen}
        onClose={closeModalAddress}
        onUpdateLocation={updateLocation}
      />
    </header>
  );
};

export default Header;
