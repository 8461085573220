// src/Pages/Login/components/HeaderLogin/HeaderLogin.jsx

import React from "react";
import { useNavigate } from "react-router-dom"; // Importando useNavigate
import "./style.css"; // Certifique-se de criar um arquivo CSS para estilização
import logo from "../../../../assets/logo.svg";

const HeaderLogin = () => {
  const navigate = useNavigate(); // Inicializando useNavigate

  const handleLogoClick = () => {
    navigate("/"); // Redireciona para a home
  };

  const handleBackClick = () => {
    navigate(-1); // Volta para a página anterior
  };

  return (
    <header className="HeaderLogin">
      <button className="back-button" onClick={handleBackClick}>
        ← Voltar
      </button>
      <div className="logoHeaderLogin" onClick={handleLogoClick}>
        <img src={logo} alt="LogoHeaderLogin" />
      </div>
    </header>
  );
};

export default HeaderLogin;
