import React, { useState } from "react";
import "./style.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const ModalAddress = ({ isOpen, onClose, onUpdateLocation }) => {
  const [cep, setCep] = useState("");
  const [numero, setNumero] = useState("");
  const [error, setError] = useState("");
  const [lojas, setLojas] = useState([]);
  const [showAvancar, setShowAvancar] = useState(false);
  const [showLojas, setShowLojas] = useState(false);

  const handleCepChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
    if (value.length <= 8) {
      // Aplica a máscara
      const formattedCep = value.replace(/(\d{5})(\d{3})/, "$1-$2");
      setCep(formattedCep);
    }
  };

  const handleSubmit = async () => {
    if (!cep) {
      setError("Por favor, preencha o campo de CEP.");
      return;
    }

    try {
      const response = await fetch(
        `https://viacep.com.br/ws/${cep.replace("-", "")}/json/`
      );
      const data = await response.json();

      if (data.erro) {
        setError("CEP inválido.");
        return;
      }

      const lojasExemplo = [
        {
          nome: "MAGICA FABRICA CACAU SHOW",
          endereco: "Rodovia Anhanguera KM 31,7, 800",
          cidade: "Cajamar",
          estado: "SP",
          cep: "07753580",
        },
      ];
      setLojas(lojasExemplo);
      setError("");
      setShowAvancar(true);
    } catch (error) {
      setError("Ocorreu um erro ao buscar os dados.");
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const fetchAddressByCep = async () => {
    try {
      const response = await fetch(
        `https://viacep.com.br/ws/${cep.replace("-", "")}/json/`
      );
      if (!response.ok) {
        throw new Error("Erro ao buscar endereço");
      }
      const data = await response.json();

      // Verifique se a API retornou um erro
      if (data.erro) {
        return; // Você pode adicionar um aviso para o usuário aqui
      }

      // Atualize a localização com os dados retornados
      const newCity = data.localidade; // Cidade
      const newState = data.uf; // Estado

      onUpdateLocation(newCity, newState);

      // Defina as lojas aqui
      const lojasExemplo = [
        {
          nome: "MAGICA FABRICA CACAU SHOW",
          endereco: "Rodovia Anhanguera KM 31,7, 800",
          cidade: "Cajamar",
          estado: "SP",
          cep: "07753580",
        },
      ];

      setLojas(lojasExemplo); // Exibe as lojas após a busca
      setShowLojas(true); // Exibe as lojas
      setShowAvancar(true); // Exibe o botão "AVANÇAR"
    } catch (error) {
      console.error("Erro ao buscar endereço:", error);
      // Aqui você pode adicionar um tratamento de erro, como uma mensagem para o usuário
    }
  };

  if (!isOpen) return null;

  return (
    <div onClick={handleOverlayClick} className="modalAddress-overlay">
      <div className="modalAddress-content">
        <button className="modalAddress-close" onClick={onClose}>
          ✖
        </button>
        <h2 className="modalAddress-title">Bem-vindo à Cacau Show</h2>
        <p className="modalAddress-description">
          Para encontrarmos os melhores preços e o estoque mais perto de você,
          por gentileza informe seu CEP:
        </p>
        <div
          className="modalAddress-inputs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <label className="modalAddress-label">
            * CEP:
            <input
              type="text"
              value={cep}
              onChange={handleCepChange}
              className="modalAddress-input"
            />
          </label>
          <label className="modalAddress-label" style={{ marginLeft: "10px" }}>
            Número:
            <input
              type="text"
              value={numero}
              onChange={(e) => setNumero(e.target.value)}
              className="modalAddress-input"
            />
          </label>
        </div>
        {error && <p className="error-message">{error}</p>}
        <button
          onClick={fetchAddressByCep}
          className="modalAddress-button"
          style={{ display: showAvancar ? "none" : "block" }}
        >
          BUSCAR
        </button>
        {showLojas && (
          <div className="lojas-list">
            <h3 className="lojas-title">Escolha uma opção de loja abaixo:</h3>
            {lojas.map((loja, index) => (
              <div key={index} className="loja-item">
                <div className="loja-texto">
                  <h4 className="loja-nome">{loja.nome}</h4>
                  <p className="loja-endereco">{loja.endereco}</p>
                  <p className="loja-localizacao">
                    {loja.cidade} - {loja.estado} - {loja.cep}
                  </p>
                </div>
                <button className="loja-selecionar">
                  <i className="fas fa-check"></i>
                </button>
              </div>
            ))}
            {showAvancar && (
              <button
                onClick={() => {
                  onClose();
                }}
                className="modalAddress-button"
              >
                AVANÇAR
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalAddress;
