import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Card from "../../../../components/Card/Card";
import "./style.css";

const CardListCategory = () => {
  const containerRef = useRef(null);
  const location = useLocation();
  const [products, setProducts] = useState(location.state?.products || []);

  useEffect(() => {
    if (products.length === 0) {
      const fetchProducts = async () => {
        try {
          const response = await fetch(
            `${import.meta.env.VITE_API_URL}/products/search`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({}),
            }
          );
          const data = await response.json();
          setProducts(data);
        } catch (error) {
          console.error("Erro ao buscar produtos:", error);
        }
      };

      fetchProducts();
    }
  }, [products]);

  useEffect(() => {
    if (location.state?.products) {
      setProducts(location.state.products);
    }
  }, [location.state]);

  const handleScroll = (direction) => {
    if (containerRef.current) {
      const scrollAmount = 300;
      const newScrollPosition =
        containerRef.current.scrollLeft +
        (direction === "left" ? -scrollAmount : scrollAmount);

      containerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div className="carousel-container-category">
        <div className="carousel-wrapper-category" ref={containerRef}>
          <div className="carousel-track-category">
            {Array.isArray(products) && products.length > 0 ? (
              products.map((product) => <Card key={product.id} {...product} />)
            ) : (
              <p>Nenhum produto encontrado.</p>
            )}
          </div>
        </div>
        <div className="dotted-line"></div>
        <button className="load-more-button">
          Carregar mais produtos <span>+</span>
        </button>
      </div>
    </>
  );
};

export default CardListCategory;
