// src/Pages/Cart/components/CardDelivery/CardDelivery.jsx

import React, { useState, useEffect } from "react";
import "./style.css"; // Certifique-se de ter um arquivo CSS para estilos

const CardDelivery = () => {
  const [selectedDelivery, setSelectedDelivery] = useState("");

  useEffect(() => {
    // Recupera a opção de entrega do localStorage ao montar o componente
    const savedDeliveryOption = localStorage.getItem("deliveryOption");
    if (savedDeliveryOption) {
      setSelectedDelivery(savedDeliveryOption);
    }
  }, []);

  useEffect(() => {
    if (selectedDelivery) {
      localStorage.setItem("deliveryOption", selectedDelivery);
      window.dispatchEvent(new Event("deliveryOptionUpdated"));
    }
  }, [selectedDelivery]);

  return (
    <div className="card-delivery">
      <h2>Resumo do pedido</h2>
      <div className="delivery-option">
        <input
          type="radio"
          id="total-express"
          name="delivery"
          value="Total Express"
          checked={selectedDelivery === "Total Express"}
          onChange={() => setSelectedDelivery("Total Express")}
        />
        <label htmlFor="total-express">
          Total Express (7 dias úteis) - R$ 0,00
        </label>
      </div>
      <div className="delivery-option">
        <input
          type="radio"
          id="correios-pac"
          name="delivery"
          value="Correios PAC"
          checked={selectedDelivery === "Correios PAC"}
          onChange={() => setSelectedDelivery("Correios PAC")}
        />
        <label htmlFor="correios-pac">
          Correios PAC (5 dias úteis) - R$ 10,00
        </label>
      </div>
      <div className="delivery-option">
        <input
          type="radio"
          id="correios-sedex"
          name="delivery"
          value="Correios Sedex"
          checked={selectedDelivery === "Correios Sedex"}
          onChange={() => setSelectedDelivery("Correios Sedex")}
        />
        <label htmlFor="correios-sedex">
          Correios Sedex (1 dia útil) - R$ 20,00
        </label>
      </div>
      <hr />
    </div>
  );
};

export default CardDelivery;
