import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./styles.css"; // Certifique-se de criar um arquivo CSS para estilização
import "@fortawesome/fontawesome-free/css/all.min.css";

const formatCurrency = (value) => {
  if (typeof value !== "number") {
    // return "R$ 0,00"; // Retorna um valor padrão se não for um número
  }
  const truncatedValue = Math.trunc(value * 100) / 100; // Trunca o valor para duas casas decimais
  return `R$ ${truncatedValue.toFixed(2).replace(".", ",")}`; // Formata para R$ e substitui ponto por vírgula
};

const Card = ({ id, name, images, price, second_price, available }) => {
  return (
    <Link to={`/produtos/${id}`} className="product-tile">
      <div className="card-product-container">
        <button className="favorite-button">
          <svg
            width="19"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
              fill="none"
              stroke="#1976d2"
              strokeWidth="2"
            />
          </svg>
        </button>

        <div className="image-container">
          <img src={images[0]} alt={name} />
        </div>
        <div className="product-title-wrapper">
          <div className="product-title-container">
            <h3 className="product-title">{name}</h3>

            <div className="tags">
              <span className="tag-cacau">2 CACAUS</span>
              <br />
              <span className="tag-chocofriday">Chocofriday</span>
            </div>

            <div className="price-container">
              <div className="price-details">
                <span className="original-price">
                  {formatCurrency(second_price)}
                </span>
                <div className="current-price">
                  <span className="currency">R$</span>
                  <span className="price-value">{formatCurrency(price)}</span>
                  <span className="price-unit">/un</span>
                </div>
              </div>
              {/* <div className="quantity-selector">
                <button className="quantity-button">-</button>
                <span className="quantity-value">1</span>
                <button className="quantity-button">+</button>
              </div> */}
            </div>
          </div>
        </div>

        {!available && (
          <div className="unavailable">
            ADICIONAR AO CARRINHO¬
            <i className="fas fa-shopping-cart"></i>
          </div>
        )}
      </div>
    </Link>
  );
};

export default Card;
