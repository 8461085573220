// src/Pages/Register/components/Register/RegisterForm.jsx
import React, { Component } from "react";
import "./style.css"; // Importando o arquivo CSS
import { useNavigate } from "react-router-dom"; // Importando useNavigate

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: "",
      sobrenome: "",
      dataNascimento: "",
      genero: "",
      cpf: "",
      telefone: "",
      email: "",
      confirmarEmail: "",
      senha: "",
      confirmarSenha: "",
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    // Aplicando máscara ao CPF
    if (name === "cpf") {
      const maskedValue = value
        .replace(/\D/g, "") // Remove caracteres não numéricos
        .replace(/(\d{3})(\d)/, "$1.$2") // Adiciona o primeiro ponto
        .replace(/(\d{3})(\d)/, "$1.$2") // Adiciona o segundo ponto
        .replace(/(\d{3})(\d{2})$/, "$1-$2"); // Adiciona o traço

      // Verifica se o CPF tem 11 dígitos
      if (maskedValue.replace(/\D/g, "").length <= 11) {
        this.setState({ [name]: maskedValue });
      }
      return;
    }

    // Aplicando máscara ao Telefone
    if (name === "telefone") {
      const maskedValue = value
        .replace(/\D/g, "") // Remove caracteres não numéricos
        .replace(/(\d{2})(\d)/, "($1) $2") // Adiciona parênteses e espaço
        .replace(/(\d{5})(\d)/, "$1-$2") // Adiciona o traço para números de 5 dígitos
        .replace(/(\d{4})(\d{1,4})$/, "$1-$2"); // Adiciona o traço para números de 4 ou 5 dígitos

      // Verifica se o telefone tem 12 dígitos
      if (maskedValue.replace(/\D/g, "").length <= 11) {
        this.setState({ [name]: maskedValue });
      }
      return;
    }

    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    // Validação dos campos obrigatórios
    const {
      nome,
      sobrenome,
      dataNascimento,
      genero,
      cpf,
      telefone,
      email,
      confirmarEmail,
      senha,
      confirmarSenha,
    } = this.state;

    // Removendo a máscara do CPF e telefone
    const cleanCpf = cpf.replace(/\D/g, ""); // Remove a máscara do CPF
    const cleanTelefone = telefone.replace(/\D/g, ""); // Remove a máscara do telefone

    // Verifica se o telefone tem 11 dígitos
    if (cleanTelefone.length !== 11) {
      alert("O telefone deve ter 11 caracteres.");
      return;
    }

    if (
      !nome ||
      !sobrenome ||
      !dataNascimento ||
      !genero ||
      !cleanCpf ||
      !cleanTelefone ||
      !email ||
      !confirmarEmail ||
      !senha ||
      !confirmarSenha
    ) {
      alert("Por favor, preencha todos os campos obrigatórios.");
      return;
    }
    try {
      const data = {
        first_name: this.state.nome,
        last_name: this.state.sobrenome,
        birth_date: this.state.dataNascimento,
        gender: this.state.genero,
        cpf: cleanCpf, // Usando CPF limpo
        phone: cleanTelefone, // Usando telefone limpo
        email: this.state.email,
        confirm_email: this.state.confirmarEmail,
        password: this.state.senha,
        confirm_password: this.state.confirmarSenha,
      };
      const response = await fetch(`${import.meta.env.VITE_API_URL}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // Convertendo o estado para JSON
      });

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(
          `Erro na resposta da API: ${
            JSON.stringify(responseData.error) || "Erro desconhecido"
          }`
        ); // Tratamento de erro
      }

      // Salvar todos os dados do formulário no localStorage
      const userData = {
        nome: this.state.nome,
        sobrenome: this.state.sobrenome,
        dataNascimento: this.state.dataNascimento,
        genero: this.state.genero,
        cpf: cleanCpf, // Usando CPF limpo
        telefone: cleanTelefone, // Usando telefone limpo
        email: this.state.email,
        confirmarEmail: this.state.confirmarEmail,
        senha: this.state.senha,
        confirmarSenha: this.state.confirmarSenha,
      };
      localStorage.setItem("userData", JSON.stringify(userData)); // Armazenando todos os dados do usuário

      // Redirecionar para a página inicial após o registro
      this.props.navigate("/"); // Redireciona para a página inicial
    } catch (error) {
      console.error(error); // Adicionando log de erro
    }
  };

  render() {
    return (
      <div className="register-container">
        <h1 className="register-title">Criar Conta</h1>
        <form onSubmit={this.handleSubmit}>
          <label>* Nome</label>
          <input
            className="input-field"
            type="text"
            name="nome"
            required
            onChange={this.handleChange}
          />
          <label>* Sobrenome</label>
          <input
            className="input-field"
            type="text"
            name="sobrenome"
            required
            onChange={this.handleChange}
          />
          <label>* Data de Nascimento</label>
          <input
            className="input-field"
            type="date"
            name="dataNascimento"
            required
            onChange={this.handleChange}
          />
          <label>* Gênero</label>
          <select
            className="input-field"
            name="genero"
            required
            onChange={this.handleChange}
          >
            <option value="">* Gênero</option>
            <option value="masculino">Masculino</option>
            <option value="feminino">Feminino</option>
            <option value="outro">Outro</option>
          </select>
          <label>* CPF</label>
          <input
            className="input-field"
            type="text"
            name="cpf"
            required
            value={this.state.cpf}
            onChange={this.handleChange}
          />
          <label>* Telefone</label>
          <input
            className="input-field"
            type="text"
            name="telefone"
            required
            value={this.state.telefone}
            onChange={this.handleChange}
          />
          <label>* Email</label>
          <input
            className="input-field"
            type="email"
            name="email"
            required
            onChange={this.handleChange}
          />
          <label>* Confirmar E-mail</label>
          <input
            className="input-field"
            type="email"
            name="confirmarEmail"
            required
            onChange={this.handleChange}
          />
          <label>* Senha</label>
          <input
            className="input-field"
            type="password"
            name="senha"
            required
            onChange={this.handleChange}
          />
          <label>* Confirmar Senha</label>
          <input
            className="input-field"
            type="password"
            name="confirmarSenha"
            required
            onChange={this.handleChange}
          />
          <button className="submit-button" type="submit">
            CRIAR CONTA
          </button>
        </form>
      </div>
    );
  }
}

// Adicionando o HOC para fornecer o navigate como prop
export default (props) => {
  const navigate = useNavigate();
  return <RegisterForm {...props} navigate={navigate} />;
};
