import React, { useEffect, useState } from "react";
import { useCart } from "../../contexts/CartContext";
import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Information from "../../components/Information/Information.jsx";
import ProductCard from "./components/ProductCard/ProductCard.jsx";
import ImgProduct from "./components/ImgProduct/ImgProduct.jsx";
import Description from "./components/Description/Description.jsx";
import Breadcrumb from "./components/Breadcrumb/Breadcrumb.jsx";
import { useParams, useNavigate } from "react-router-dom";

const Product = ({ children }) => {
  const { id, name } = useParams();
  const [productData, setProductData] = useState(null);
  const { addToCart } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductData = async () => {
      if (!id) {
        return;
      }
      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/product/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setProductData(data);

        localStorage.setItem("productName", data.name);

        if (data && data.name !== name) {
          navigate(`/produtos/${data.id}`);
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProductData();
  }, [id, name, navigate]);

  useEffect(() => {
    const productName = localStorage.getItem("productName");
    if (productName) {
      document.title = productName;
    }
  }, [productData]);

  return (
    <div>
      <Header />
      <Breadcrumb name={productData?.name} />
      <main>{children}</main>
      <ImgProduct images={productData?.images} alt="Descrição das imagens" />
      {productData && (
        <>
          <ProductCard product={productData} onAddToCart={addToCart} />
        </>
      )}
      <Description description={productData?.description} />
      <Information />
      <Footer />
    </div>
  );
};

export default Product;
