import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import CardCart from "./components/CardCart/CardCart.jsx";
import ResumeOrder from "./components/ResumeOrder/ResumeOrder.jsx";
import CardDelivery from "./components/CardDelivery/CardDelivery.jsx";
import "./style.css";

const Cart = ({ children }) => {
  const [items, setItems] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);

  useEffect(() => {
    document.title = "Carrinho de Compras";

    const fetchItems = () => {
      const storedItems = JSON.parse(localStorage.getItem("cart")) || [];
      setItems(storedItems);
      const newTotalQuantity = storedItems.reduce(
        (acc, item) => acc + item.quantity,
        0
      );
      setTotalQuantity(newTotalQuantity);
    };

    fetchItems();
  }, []);

  useEffect(() => {
    const root = document.documentElement;
    root.style.paddingBottom = "120px";

    return () => {
      root.style.paddingBottom = "";
    };
  }, []);

  const handleRemoveItem = (id) => {
    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
    localStorage.setItem("cart", JSON.stringify(updatedItems));

    const newTotalQuantity = updatedItems.reduce(
      (acc, item) => acc + item.quantity,
      0
    );
    setTotalQuantity(newTotalQuantity);

    if (updatedItems.length === 0) {
      setTotalQuantity(0);
    }
  };

  return (
    <div>
      <Header key={totalQuantity} totalQuantity={totalQuantity} />

      <h1 className="car-pay">Carrinho de compras</h1>

      {items.length === 0 ? (
        <div className="alert alert-warning">
          Seu carrinho de compras está vazio! Adicione seu produto Cacau Show.
          <button className="btn btn-primary">Continuar comprando</button>
        </div>
      ) : (
        <>
          {items.map((item, index) => (
            <CardCart key={index} item={item} onRemove={handleRemoveItem} />
          ))}
          <CardDelivery />
          <ResumeOrder />
        </>
      )}
      <Footer />
    </div>
  );
};

export default Cart;
