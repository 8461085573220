import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CardListCategory from "./components/CardListCategory/CardListCategory";
import Information from "../../components/Information/Information";
import BreadcrumbCategory from "./components/BreadcrumbCategory/BreadcrumbCategory";

const Category = () => {
  useEffect(() => {
    document.title = "Categoria";
  }, []);

  return (
    <>
      <Header />
      <BreadcrumbCategory />
      <CardListCategory />
      <Information />
      <Footer />
    </>
  );
};

export default Category;
