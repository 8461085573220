import React, { useRef, useEffect, useState } from "react";
import Banner from "../Banner/Banner";
import "./style.css";

const BannerList = ({ parametro }) => {
  const containerRef = useRef(null);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch(`${import.meta.env.VITE_API_URL}/banners`);
        const data = await response.json();
        setBanners(data);
      } catch (error) {}
    };

    fetchBanners();
  }, []);

  const handleScroll = (direction) => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const newScrollPosition =
        containerRef.current.scrollLeft +
        (direction === "left" ? -containerWidth : containerWidth);

      containerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="carousel-container-bannerList">
      <button
        className="carousel-button carousel-button-left"
        onClick={() => handleScroll("left")}
        aria-label="Anterior"
      >
        <span>‹</span>
      </button>

      <div className="bannerList-carousel-wrapper" ref={containerRef}>
        <div className="bannerList-carousel-track">
          {Array.isArray(banners) ? (
            banners.map((banner) => (
              <Banner key={banner.id} image={banner.image} />
            ))
          ) : (
            <p>Nenhum produto disponível.</p>
          )}
        </div>
      </div>

      <button
        className="carousel-button carousel-button-right"
        onClick={() => handleScroll("right")}
        aria-label="Próximo"
      >
        <span>›</span>
      </button>
    </div>
  );
};

export default BannerList;
