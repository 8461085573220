import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Importando useNavigate
import "./style.css"; // Certifique-se de criar um arquivo CSS

const Sidebar = () => {
  const [open, setOpen] = useState({});
  const [categories, setCategories] = useState([]); // Estado para armazenar categorias
  const navigate = useNavigate(); // Inicializando o useNavigate

  const handleSidebar = async () => {
    const response = await fetch(`${import.meta.env.VITE_API_URL}/categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      throw new Error(
        `Erro na resposta da API: ${
          JSON.stringify(responseData.error) || "Erro desconhecido"
        }`
      ); // Tratamento de erro
    }

    setCategories(responseData); // Armazenando as categorias no estado
  };

  useEffect(() => {
    handleSidebar(); // Chama a função ao montar o componente
  }, []);

  const handleSubcategoryClick = (id) => {
    navigate(`/category/${id}`); // Redireciona para a página da subcategoria
  };

  return (
    <div className="sidebar-container">
      {categories.map((category) => (
        <div key={category.id} className="category-spacing">
          <div
            className="categorysidebar-button"
            onClick={() =>
              setOpen({ ...open, [category.name]: !open[category.name] })
            }
          >
            {category.name}
            <span className="arrow-sidebar">
              {open[category.name] ? "▲" : "▼"}
            </span>
          </div>
          {open[category.name] && (
            <div className="submenu">
              {category.subcategories.map((subcategory) => (
                <button
                  key={subcategory.id}
                  className="subcategorysidebar-button"
                  onClick={() => handleSubcategoryClick(subcategory.id)}
                >
                  Ir para {subcategory.name}
                </button>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
