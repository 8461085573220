import React from "react";
import "./style.css";

const Breadcrumb = () => {
  return (
    <nav>
      <ul>
        <li className="arrow-breadcrumb">
          <i className="fas fa-chevron-left"></i>
        </li>
        <li>
          <a href="/">HOME</a>
        </li>
        <li className="separator-breadcrumb">
          <i className="fas fa-chevron-right"></i>
        </li>
        <li className="current">NATAL</li>
      </ul>
    </nav>
  );
};

export default Breadcrumb;
