import React, { useState, useEffect } from "react";
import "./style.css"; // Importando o CSS para estilização
import MaskedInput from "react-text-mask"; // Importando a nova biblioteca de máscara
import { useNavigate } from "react-router-dom"; // Importando o useNavigate

const AddressModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate(); // Inicializando o useNavigate

  const [formData, setFormData] = useState({
    cep: "",
    identificacao: "",
    endereco: "",
    numero: "",
    complemento: "",
    cidade: "",
    estado: "",
    nome: "",
    sobrenome: "",
    telefone: "",
    enderecoCobrança: false,
    cpf: "",
    email: "",
  });

  const [cepTemp, setCepTemp] = useState(""); // Estado temporário para o CEP sem máscara

  const handleClickOutside = (e) => {
    const modal = document.querySelector(".address-modal");
    if (modal && !modal.contains(e.target)) {
      onClose(); // Fecha o modal se o clique for fora dele
    }
  };

  useEffect(() => {
    document.body.classList.add("modal-open");
    document.addEventListener("mousedown", handleClickOutside); // Adiciona o evento de clique fora do modal

    // Preenchendo os dados do localStorage
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setFormData((prevData) => ({
        ...prevData,
        nome: userData.nome,
        sobrenome: userData.sobrenome,
        cpf: userData.cpf,
        telefone: userData.telefone,
        email: userData.email,
      }));
    }

    return () => {
      document.body.classList.remove("modal-open");
      document.removeEventListener("mousedown", handleClickOutside); // Remove o evento ao desmontar
    };
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const buscarEndereco = async (cep) => {
    if (cep.length === 8) {
      // Verifica se o CEP tem 8 dígitos
      try {
        const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
        const data = await response.json(); // Converte a resposta para JSON
        if (!data.erro) {
          setFormData({
            ...formData,
            endereco: data.logradouro,
            cidade: data.localidade,
            estado: data.uf,
          });
        } else {
          alert("CEP não encontrado.");
        }
      } catch (error) {
        console.error("Erro ao buscar o endereço:", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      street: formData.endereco,
      city: formData.cidade,
      state: formData.estado,
      complement: formData.complemento,
      number: formData.numero,
      name: formData.identificacao,
      zip_code: formData.cep,
      phone: formData.telefone,
      cpf: formData.cpf,
      email: formData.email,
      first_name: formData.nome,
      last_name: formData.sobrenome,
    };

    const response = await fetch(`${import.meta.env.VITE_API_URL}/address`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      // Verifica se a resposta é 200
      const result = await response.json(); // Obtém o resultado da resposta
      localStorage.setItem("userId", result.user.id); // Salva o ID do usuário no localStorage
      localStorage.setItem("userEmail", result.user.email); // Salva o email do usuário no localStorage
      localStorage.setItem("userName", result.user.first_name); // Salva o nome do usuário no localStorage
      localStorage.setItem("userPhone", result.user.phone); // Salva o telefone do usuário no localStorage
      localStorage.setItem("userAddress", result.address.street); // Salva o endereço do usuário no localStorage
      localStorage.setItem("userCity", result.address.city); // Salva a cidade do usuário no localStorage
      localStorage.setItem("userZipCode", result.address.zip_code); // Salva o CEP do usuário no localStorage
      localStorage.setItem("userState", result.address.state); // Salva o estado do usuário no localStorage
      localStorage.setItem("userComplement", result.address.complement); // Salva o complemento do usuário no localStorage
      localStorage.setItem("userNumber", result.address.number); // Salva o número do usuário no localStorage
      localStorage.setItem("userCpf", result.user.cpf); // Salva o CPF do usuário no localStorage
      localStorage.setItem("address", JSON.stringify(data)); // Salva todos os dados do endereço no localStorage
      localStorage.setItem(
        "userData",
        JSON.stringify({
          // Salva os dados do usuário no localStorage
          id: result.user.id,
          email: result.user.email,
          first_name: result.user.first_name,
          last_name: result.user.last_name,
          phone: result.user.phone,
          cpf: result.user.cpf,
        })
      );
      onClose(); // Fecha o modal
      navigate("/payments"); // Redireciona para a página de pagamentos
    } else {
      // Aqui você pode adicionar lógica para tratar erros
    }
  };

  return (
    <div className={`address-modal-container ${isOpen ? "open" : ""}`}>
      <div className="address-modal">
        <button onClick={onClose} className="close-button">
          ✖
        </button>
        <h2>Cadastrar endereço</h2>
        <p className="info-text">Preencha os campos indicados</p>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label>
              CEP
              <MaskedInput
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]} // Máscara para o CEP
                name="cep"
                value={formData.cep}
                onChange={(e) => {
                  handleChange(e);
                  setCepTemp(e.target.value.replace(/\D/g, "")); // Atualiza o estado temporário sem máscara
                }}
                onBlur={() => buscarEndereco(cepTemp)} // Chama a função com o CEP sem máscara
                placeholder="Insira seu CEP"
                className="input-field"
              />
            </label>
          </div>
          <label>
            Endereço*
            <input
              type="text"
              name="endereco"
              value={formData.endereco}
              onChange={handleChange}
              className="input-field"
            />
          </label>
          <label>
            Cidade
            <input
              type="text"
              name="cidade"
              value={formData.cidade}
              onChange={handleChange}
              className="input-field"
            />
          </label>
          <label>
            Estado (UF)
            <input
              type="text"
              name="estado"
              value={formData.estado}
              onChange={handleChange}
              className="input-field"
            />
          </label>
          <label>
            Identificação do endereço
            <input
              type="text"
              name="identificacao"
              value={formData.identificacao}
              onChange={handleChange}
              placeholder="(Ex. Casa, escritório, etc)"
              className="input-field"
            />
          </label>
          <div className="input-group">
            <label>
              Número
              <input
                type="text"
                name="numero"
                value={formData.numero}
                onChange={handleChange}
                placeholder="Número..."
                className="input-field"
              />
            </label>
            <label>
              Complemento
              <input
                type="text"
                name="complemento"
                value={formData.complemento}
                placeholder="(Ex. Casa, escritório, etc)"
                onChange={handleChange}
                className="input-field"
              />
            </label>
          </div>
          <div className="input-group">
            <label>
              Nome
              <input
                type="text"
                name="nome"
                value={formData.nome}
                onChange={handleChange}
                className="input-field"
              />
            </label>
            <label>
              Sobrenome
              <input
                type="text"
                name="sobrenome"
                value={formData.sobrenome}
                onChange={handleChange}
                className="input-field"
              />
            </label>
          </div>
          <label>
            Telefone
            <MaskedInput
              mask={[
                "(",
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]} // Máscara para o telefone
              name="telefone"
              value={formData.telefone}
              placeholder="Ex.: (41) 99888-7777"
              onChange={handleChange}
              className="input-field"
            />
          </label>
          <label>
            CPF
            <MaskedInput
              mask={[
                /\d/,
                /\d/,
                /\d/,
                ".",
                /\d/,
                /\d/,
                /\d/,
                ".",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
              ]} // Máscara para o CPF
              name="cpf"
              value={formData.cpf}
              onChange={handleChange}
              className="input-field"
            />
          </label>
          <label>
            Email
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="input-field"
            />
          </label>

          <button type="submit">CADASTRAR</button>
        </form>
      </div>
    </div>
  );
};

export default AddressModal;
